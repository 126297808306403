














































import { ShopOrderSucType } from "@/Type/index"
import { Vue,Component,Emit, Prop } from "vue-property-decorator"

interface OrderHeadType<T=string>{
    PutIcon:T;
    PhoneIcon:T;
    CopyIcon:T;

   
}

@Component({ name:"OrderHead" })
export default class OrderHead extends Vue implements OrderHeadType{
    PutIcon = require("$icon/Shop/Put.png");
    PhoneIcon = require("$icon/Shop/Phone.png");
    CopyIcon = require("$icon/Shop/Copy.png");

    handleCopyPhone(str:string){
       if( !str.length )return;
       this.PullCopy(str)
    }

   @Emit("PullCopy")
   PullCopy(data:string){
       return data
   }

   @Prop(Object)
   data!:ShopOrderSucType
   get GetData(){
       return this.data
   }
}
