import Storage from "@/util/Storage";
import {GetSchoolRoom} from "@/Api/Basics";
import {UserStore} from "@/store/modules/User";
import {FilterPullItem} from "@/util/filter";
import {IsJoinGroupChat, JoinGroupChat} from "@/Api/Friend";

// 请求送件地址
export function getToList() {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(GetSchoolRoom({ userId,token }).then(res=>{
        UserStore.SetRoomList( res.data.buildNumbers )
        return res.data && FilterPullItem(res.data.buildNumbers,"dicCode") || []
    }))
}

// 加入 群
export function joinGroupChat(groupId="") {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(JoinGroupChat({ userId,token },{ groupId }).then(res=>{
        return res.data
    }))
}

// 判断 是否加入群
export function isGroupChat(groupId="") {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(IsJoinGroupChat({ userId,token },{ groupId }).then(res=>{
        if ( res.data ){
            return res.data
        }else{
            return joinGroupChat(groupId).then(res=>res)
        }
    }))
}
