
export declare const appJs:any
export declare const Android:any

export function setPullData(data:any = {},type = "") {
    let phone = navigator.userAgent;
    let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1;
    let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
    let zfbBool = phone.toLowerCase().match(/AlipayClient/i)
    if ( wxBool != null && wxBool[0] == "micromessenger" ){
        // 微信支付
    }else if( zfbBool != null && zfbBool[0] == 'alipayclient'){
        // 支付宝支付
    }else if ( isAndroid ){
        // 微信支付
    }else if ( isiOS ){
        // 微信支付
        let TitleDom:any = document.querySelector("title")
        TitleDom.innerText = '' || '三易校园'
    }
}
