























































import { Toast,Dialog } from "vant"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { ShopOrderSucType } from "@/Type/index"
import { Vue,Component,Prop, Watch } from "vue-property-decorator"

 interface OrderContType<T=string>{
     RightIcon:T;
     ShopIcon:T;
     PhoneIcon:T;
     OrderList:Array<{ title:T;content:T; }>

     initData():void;
     handleToPathSuc():void
 }

 @Component({ name:"OrderCont" })
 export default class OrderCont extends Vue implements OrderContType{
     RightIcon = require("$icon/Basics/Right.png");
     ShopIcon = require("$icon/Shop/TestShop.png");
     PhoneIcon = require("$icon/Shop/Phone.png")
     OrderList = [
         { title:"配送费", content:"￥ 0" },
         { title:"餐盒费", content:"￥ 0" },
         { title:"打包费", content:"￥ 0" },
         { title:"总计", content:"￥ 0" },
     ]

     initData(){
         this.OrderList[0].content = "￥ " + this.GetData.fee
         this.OrderList[1].content = "￥ " + this.GetData.lunchboxFee
         this.OrderList[2].content = "￥ " + this.GetData.packFee
         this.OrderList[3].content = "￥ " + this.GetData.ptotal
     }

     handleToPathSuc(){
         if( Number( this.GetData.storeStatus ) > 0 ){
            let str = ""
            switch( Number( this.GetData.storeStatus ) ){
                case 1:
                    str = "商铺已下架"
                    break;
                case 2:
                    Dialog.confirm({
                        title: '温馨提醒',
                        message: '店铺已歇业,是否调转到店铺详情？',
                        confirmButtonText:"确定",
                        cancelButtonText:"取消",
                        className:"HideClassName",
                        overlayClass:"MaskHideClassName"
                    }).then(res=>{
                        this.$router.push({
                            path:"/shopSuc",
                            query:{
                                id:this.GetData.id as string,
                                title:this.GetData.name as string
                            }
                        })
                    }).catch( cat=>cat )
                    return;
                case 3:
                    str = "商铺未通过审核"
                    break;
            }
            Toast({
                message:str,
                icon:"none",
                className:"ToastClassName"
            })
        }else{
            if( ShopSucStore.GetShopSuc.id != this.GetData.id ){
                ShopSucStore.SetOnShopList( [] )
                ShopSucStore.SetAppointShop( false )
            }
            this.$router.push({
                path:"/shopSuc",
                query:{
                    id:this.GetData.storeId as string,
                    title:this.GetData.name as string
                }
            })
        }
     }

     @Prop(Object)
     data!:ShopOrderSucType

     get GetData(){
         return this.data
     }

     @Watch("GetData")
     ChangeData(newVal:any){
         this.initData()
     }
 }
