











































































 import { Notify,Toast } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import Head from "@/components/ShopComponent/OrderSuc/Head.vue"
 import Cont from "@/components/ShopComponent/OrderSuc/Cont.vue"
 import Stroage from "@/util/Storage"
 import { handleFilterDate as FilterDate } from "@/util/index"
 import { ShopOrderSucType } from "@/Type/index"
 import { MyOrderSuc,PayBefore,PayAfter,BackPay,BackOrder } from "@/Api/Shop/index"
 import { Vue,Component,Ref, Watch } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";
 import {setPullData} from "@/util/compatibility_App";
 import {isGroupChat} from "@/server";

 // let WeixinJSBridge:any = ( "WeixinJSBridge" in window  ) ? window.WeixinJSBridge : {}; // {}//
 declare let WeixinJSBridge: any;
 declare const document:any;

 interface OrderSucType<T=string>{
     userId:T|number;
     token:T;
     loadingShow:boolean
     data:ShopOrderSucType
     OrderList:Array<any>
     OrderData:any

     initData():void;
     handleCopyPhone(str:string):void;
     handleBackOrder():void;
     handleBackPay():void;
     handleUpData():void;
     handleClickPay():void;
     handlePay():void;
     onBridgeReady():void;
     handleCallBack():void;
     handleFilterState():void;
     handleError(data:string):void;
 }

 @Component({ name:"OrderSuc",components:{ HeadTop,Head,Cont } })
 export default class OrderSuc extends ZoomPage implements OrderSucType{
     loadingShow = true
     userId:string|number = ""
     token = ""
     RightIcon = require("$icon/Basics/Right.png");
     ShopIcon = require("$icon/Shop/TestShop.png");
     PhoneIcon = require("$icon/Shop/Phone.png");
     data:ShopOrderSucType = {}
     OrderList = [
        { title:"支付方式", content:"在线支付" },
        { title:"下单时间", content:"2021-01-24 9:46:33" },
        { title:"更新时间", content:"2021-01-25 10:50:30" },
     ]
     OrderData:any = {}

     activated() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.initData()
     }

     initData(){
        this.handleUpData()
     }

     handleCopyPhone(str:string){
        if( !str.length )return;
        this.GetCopy.value = str
        this.GetCopy.select()
        document.execCommand("copy");
        Toast({
            message:"复制成功",
            icon:"none",
            className:"ToastClassName"
        })
     }

     handleFeedback(){
         let id = process.env.NODE_ENV === "development" ? "2" : '19'
         isGroupChat(id).then(res=>{
             let { name:title = "",userId:groupHost = "" } = res;
             let query:any = {
                 id,
                 title,
                 groupHost,
                 state:"groupChat",
                 type:"shopFeedback",
             }
             if ( groupHost != Stroage.GetData_?.("userId") ){
                 query["orderData"] = JSON.stringify(this.data)
             }
             this.$router.push({
                 name:"chat",
                 query
             })
         })

     }

     handleBackOrder(){
         this.loadingShow = true
         BackOrder({
             userId:this.userId,
             token:this.token
         },{
             orderId:this.data.orderId as string,
             status:6,
         }).then(res=>{
             this.loadingShow = false
            if( res.message.msg === "200" ){
                 Toast({
                    message:"取消成功",
                    icon:"none",
                    className:"ToastClassName"
                 })
                 this.handleUpData()
             }else{
                 this.handleError( res.message.msg )
             }
         })
     }

     handleBackPay(){
         this.loadingShow = true
         BackPay({
             userId:this.userId,
             token:this.token
         },{
             orderId:this.data.orderId as string
         }).then(res=>{
             this.loadingShow = false
             if( res.message.msg === "200" ){
                 Toast({
                    message:"退款成功",
                    icon:"none",
                    className:"ToastClassName"
                 })
                 this.handleUpData()
             }else{
                 this.handleError( res.message.msg )
             }
         })
     }

     handleUpData(){
        MyOrderSuc({
            userId:this.userId,
            token:this.token
        },{
            orderId:this.$route.query.orderId as string,
            pageNo:1,
            pageSize:15
        }).then( res=>{
            console.log( res )
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.data = res.data
                this.$forceUpdate()
            }else{
                this.handleError( res.message.msg )
            }
        } )
     }

     handleClickPay(){
         let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
         if ( bool != null && bool[0] == "micromessenger" ){
             this.loadingShow = false
             setPullData({ orderId:this.$route.query.orderId as string })
             return
         }
        this.loadingShow = true
        PayBefore({
            userId:this.userId,
            token:this.token
        },{
            orderId:this.$route.query.orderId as string,
        }).then(res=>{
            console.log( res )
            if( res.message.code === "200" ){
                this.OrderData = res.data
                this.handlePay()
            }else{
                this.loadingShow = false
                this.handleError( res.message.msg )
            }
        })
     }

     handlePay(){
        if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            }else if ( "attachEvent" in document ){
                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
            }
        }else{
            this.onBridgeReady();
        }
     }

     onBridgeReady(){
        ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                "appId":this.OrderData.appId,//公众号名称，由商户传入
                "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
                "nonceStr":this.OrderData.nonceStr, //随机串
                "package":this.OrderData.package,
                "signType":this.OrderData.signType,//微信签名方式：
                "paySign":this.OrderData.paySign //微信签名
            },
            (res:any)=>{
                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                    this.handleCallBack()
                }
            });
     }

     handleCallBack(){
         PayAfter({
            userId:this.userId,
            token:this.token
         },{
             orderId:this.$route.query.orderId as string,
         }).then( res=>{
             console.log( res )
             this.loadingShow = false
             if( res.message.code === "200" ){
                Toast({
                    message:"支付成功",
                    icon:"none",
                    className:"ToastClassName"
                })
                this.handleUpData()
             }else{
                 this.handleError( res.message.msg )
             }
         } )
     }

     handleFilterState(){
         switch( this.data.status ){
             case 0:
                 return "待付款";
             case 1:
                 return "待接单";
             case 2:
                 return "待配送";
             case 3:
                 return "待取货";
             case 4:
                 return "配送中";
             case 5:
                 return "已完成";
             case 6:
                 return "已取消";
             case 7:
                 return "已失效";
             case 8:
                 return "已退款";
             case 9:
                 return "异常订单";
             default :
                 return "啥也不是"
         }
     }

     handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
     }

    @Ref("Copy")
    Copy!:HTMLInputElement;
    get GetCopy(){
        return this.Copy
    }

    @Watch("data")
    ChangeData(newVal:any){
        this.OrderList[1].content = newVal.createTime
        this.OrderList[2].content = newVal.updateTime
    }

    get FilterState(){
         switch( this.data.status ){
             case 1:
                 return "等待商家接单中..."
             case 2:
                 return "等待配送员接单中..."
             case 3:
                return FilterDate(this.data.updateTime)
             case 4:
                return FilterDate(this.data.updateTime);
             case 5:
                return FilterDate(this.data.updateTime);
             default:
                return this.handleFilterState();
         }
     }
}
